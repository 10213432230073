<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Orders</h5>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2" @keyup.enter="search($event)">
        <button class="search-icon" @click="search()">
          <img src="../../assets/images/search.png" alt="" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Type Here..."
          aria-label="Search"
          v-model="filter.search"
          @input="search($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTop",
  props: {
    count: Number,
    searched: String,
  },
  data: function (){
    return{
      filter: {
          search: "",
        },
    }
  },
  methods: {
    search() {
      console.log(this.filter);
        this.$root.$emit("searchForOrders", this.filter);
      },
	}
};
</script>

<style></style>
