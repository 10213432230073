<template>
  <div>
    <div id="mySidebar" class="sidebar">
      <ul class="menu-list">
        <li class="menu-item">
          <router-link  to="/orders" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/order.png" alt="" />
            </div>
            <div class="item-name">Orders</div>
          </router-link>
        </li>
        <li class="menu-item">
          <router-link  to="/customers" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/customer.png" alt="" />
            </div>
            <div class="item-name">Customers</div>
          </router-link>
        </li>
        <li class="menu-item">
          <router-link  to="/RefundOrders" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/refund.png" alt="" />
            </div>
            <div class="item-name">Refund Orders</div>
          </router-link>
        </li>    
        <li class="menu-item" v-if="User">
          <router-link  to="/Subscriptions" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/subscribe.png" alt="" />
            </div>
            <div class="item-name">Subcriptions</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="isAdmin">
          <router-link  to="/subscriptions" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/subscribe.png" alt="" />
            </div>
            <div class="item-name">Admin Subcriptions</div>
          </router-link>
        </li>
      </ul>
      <div class="closebtn" @click="closeNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
      <div id="main" @click="openNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      User:false,
      isAdmin:false,
    };
  },
  methods: {
   
    openNav: function () {
      var x = window.matchMedia("(min-width: 769px)");

      if (x.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:180px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
      }

      var y = window.matchMedia("(max-width: 768px)");

      if (y.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:50px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document
          .querySelector(".registry-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
        document
          .querySelector(".home-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
      }
    },

    closeNav: function () {
      document.getElementById("mySidebar").setAttribute("style", "width:0");
      document.getElementById("main").setAttribute("style", "display:block");
      document.querySelector(".closebtn").setAttribute("style", "display:none");
    },
  },
  mounted() {
  //   if(this.user.user.role_id === 1){
  //   this.isAdmin=true
  //   }
  //  if (localStorage.getItem("permissions") == 2) {
  //       this.User= true;
  //   }
  if (localStorage.getItem("permissions") == 2) {
    this.User = true;
} else if (localStorage.getItem("permissions") == 1) {
    this.isAdmin = true;
}
  },
};
</script>

<style scoped>
.usertab {
  height: 17px;
  width: 20px
}

</style>
