var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-nav"},[_vm._m(0),_c('div',{staticClass:"nav-content"},[_c('ul',[_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-link red-circle",attrs:{"data-toggle":"modal","data-target":"#exampleModalCenter"},on:{"click":_vm.toggleNotifications}},[_c('img',{attrs:{"src":require("../../assets/images/ios-noti.png"),"alt":"bell"}}),(_vm.notificationCount)?_c('span',{staticClass:"button__badge"},[_vm._v(_vm._s(_vm.notificationCount))]):_vm._e()]),_c('div',{staticClass:"modal fade",attrs:{"id":"exampleModalCenter","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(1),_c('div',{staticClass:"modal-body over"},[_vm._m(2),_c('ul',{staticClass:"notifications-list flex-column align-items-start"},_vm._l((_vm.notifications),function(notification){return _c('li',{key:notification.NotificationID,staticClass:"p-3 w-100"},[_vm._v(" "+_vm._s(notification.id)+" "+_vm._s(notification.FirstName)+" "+_vm._s(notification.LastName)+" "+_vm._s(notification.Message)+" "),_c('p',[_vm._v("Date: "+_vm._s(_vm._f("moment")(notification.Timestamp,"MMM D, YYYY h:mm:ss A")))])])}),0),_vm._m(3),_c('ul',{staticClass:"Suspicious_notifications flex-column"},[_vm._l((_vm.suspiciousNotifications),function(suspicious_noti){return _c('li',{key:suspicious_noti.SuspiciousActivityID,class:{
                        'danger1': suspicious_noti.Risk_level === 'medium',
                        'danger2': suspicious_noti.Risk_level === 'high',
                        'danger3': suspicious_noti.Risk_level === 'extremely high'
                      }},[_c('span',[_vm._v("Order id "+_vm._s(suspicious_noti.OrderID)+" with Customer id "+_vm._s(suspicious_noti.CustomerID)+" is at "),_c('strong',[_vm._v(_vm._s(suspicious_noti.Risk_level))]),_vm._v(" level of risk.")]),_c('p',[_vm._v("Date: "+_vm._s(_vm._f("moment")(suspicious_noti.Timestamp,'MMM D, YYYY h:mm:ss A')))])])}),_vm._l((_vm.suspiciousNotificationsRefund),function(suspicious_noti){return _c('li',{key:suspicious_noti.id,class:{
                        'danger1': suspicious_noti.Risk_level === 'medium',
                        'danger2': suspicious_noti.Risk_level === 'high',
                        'danger3': suspicious_noti.Risk_level === 'extremely high'
                      }},[_c('span',[_vm._v("Order id "+_vm._s(suspicious_noti.OrderID)+" with Customer id "+_vm._s(suspicious_noti.CustomerID)+" is at "),_c('strong',[_vm._v(_vm._s(suspicious_noti.Risk_level))]),_vm._v(" level of risk.")]),_c('p',[_vm._v("Date: "+_vm._s(_vm._f("moment")(suspicious_noti.Timestamp,'MMM D, YYYY h:mm:ss A')))])])}),_vm._l((_vm.vpnOrders),function(vpnOrder){return _c('li',{key:vpnOrder.OrderID,staticClass:"danger3"},[_c('span',[_vm._v("Order id "+_vm._s(vpnOrder.OrderID)+" with Customer id "+_vm._s(vpnOrder.CustomerID)+" is done using VPN.")]),_c('p',[_vm._v("Date: "+_vm._s(_vm._f("moment")(vpnOrder.Timestamp,'MMM D, YYYY h:mm:ss A')))])])})],2),_vm._m(4),_c('ul',{staticClass:"Suspicious_notifications flex-column"},_vm._l((_vm.vpnOrders),function(vpnOrder){return _c('li',{key:vpnOrder.OrderID,staticClass:"danger3"},[_c('span',[_vm._v("Order id "+_vm._s(vpnOrder.OrderID)+" with Customer id "+_vm._s(vpnOrder.CustomerID)+" is done using VPN.")]),_c('p',[_vm._v("Date: "+_vm._s(_vm._f("moment")(vpnOrder.Timestamp,'MMM D, YYYY h:mm:ss A')))])])}),0)])])])])]),_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.userMenuOutside),expression:"userMenuOutside"}],staticClass:"nav-item",on:{"click":_vm.openUserMenu}},[_vm._m(5)])])]),(_vm.userMenu)?_c('div',{staticClass:"menu-options"},[_c('ul',[_c('a',{staticClass:"menu-link",attrs:{"href":"#","target":"_blank"}},[_vm._v("Help")]),_c('a',{staticClass:"menu-link",on:{"click":_vm.logout}},[_vm._v("Logout")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('h4',{},[_vm._v("Shock Shield.io")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLongTitle"}},[_vm._v("Notifications")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Orders & Refunds")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-2"},[_c('strong',[_vm._v("Risky Customers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-2"},[_c('strong',[_vm._v("Vpn Orders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link"},[_c('img',{attrs:{"src":require("../../assets/images/profile32.png"),"alt":""}})])
}]

export { render, staticRenderFns }