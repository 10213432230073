<template>
  <div class="table-container"  v-bind:class="classObject">
    <table class="table table-md-responsive"
      v-if="orderList.length > 0"
      >
      <!-- v-if="showLoader == 0" -->

      <thead>
        <tr>
          <th>
            OrderID
          </th>
          <th class="text-center" >Customer</th>
          <th class="text-center">Status</th>
          <!-- <th class="text-center">
            Total Price
          </th> -->
          <th class="text-center">
            Email
          </th>
                   
          <th class="text-center">
            Shop Name
          </th>
          <th class="text-center">Risk Level</th>
          <th class="text-center">
            Created At
          </th>
          <th class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in orderList" :key="index">
          <td>
            <div class="company-details">
              <h6 class="company-name">{{ item.order_number }}</h6>
            </div>
          </td>
          <td class="text-center" >{{ item?.customer?.first_name || item.Customer_id }} {{ item?.customer?.last_name }}</td>
          <td class="text-center">
            <span v-if="item.approved === null">Pending</span>
            <span v-else-if="item.approved">Approved</span>
            <span v-else>Dis-approved</span>
          </td> 
          <!-- <td class="text-center" >{{ item.total_price}}</td> -->
          <td class="text-center" >{{ item.email }}</td>
          <td class="text-center" >{{ item.shopName}}</td>
          <td class="text-center" v-if="item.suspicious_activities[0]">{{ item.suspicious_activities[0].Risk_level }}</td>
          <td class="text-center" v-else> - </td>
          <td class="text-center">{{ item.created_at | moment("MMM D, YYYY")  }}</td>
          <td class="text-center">
            <div class="dropdown">
              <button class="btn btn-default " type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                &#x2026;
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <div v-if="item.approved == null">
                  <li @click="verifyOrder(item.id)">Approved </li>
                  <li @click="unVerifyOrder(item.id)" >Dis-Approved </li>
                </div>
                <div v-else-if="item.approved !== null">
                  <li @click="verifyOrder(item.id)" v-if="item.approved == false">Approved </li>
                  <li @click="unVerifyOrder(item.id)" v-else-if="item.approved == true">Dis-Approved </li>
               </div>
              </ul>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
    <div v-if="showLoader == 0 && orderList.length == 0" class="h-75 d-flex flex-column justify-content-center">
        <p class="no-registry">No orders found.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OrderTable",

  data() {
    return {};
  },
  props: {
    orderList: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
  },
  methods: {
    verifyOrder(order_id) {
      var formData = new FormData();
      formData.append("order_id", order_id);
      formData.append("approved", true); // Setting approved to true

      axios.post(process.env.VUE_APP_API_URL + "approveOrder", formData)
        .then((response) => {
          const statusCode = response.data.statusCode || 500;
          if (statusCode === 200) {
            console.log("Order approved:", order_id);
            this.$root.$emit('update-data');

            // Optionally update UI or notify user
          }
        })
        .catch((error) => {
          console.log("Error occurred:", error);
        });
    },

    unVerifyOrder(item) {
      var formData = new FormData();
      formData.append("order_id", item);
      formData.append("approved", false); // Setting approved to false

      axios.post(process.env.VUE_APP_API_URL + "approveOrder", formData)
          .then((response) => {
            const statusCode = response.data.statusCode || 500;
            if (statusCode === 200) {
              this.$root.$emit('update-data');
              console.log("Order disapproved:", item);
            }
          })
          .catch((error) => {
            console.log("Error occurred:", error);
          });
    },
  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  
  mounted() {},
};
</script>
<style scoped>
#dropdownMenu1{
  font-weight: 800;
}
#dropdownMenu1:focus{
  border: none!important;
  box-shadow: none!important;
}
.dropdown-menu {
    background-color: var(--white);
    border-radius: 15px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    top: -7px!important;
    left: -42px!important;
    min-width: 6rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-menu li {
    padding-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    color: var(--main-black);
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: block;
}
.dropdown-menu li:hover {
  background-color: rgb(241, 240, 240);
}
</style>
