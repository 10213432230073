<template>
  <div class="top-nav">
    <div class="brand">
      <h4 class="">Shock Shield.io</h4>
    </div>
    <div class="nav-content">
      <ul>
       
        <li class="nav-item">
          <div class="nav-link red-circle" @click="toggleNotifications" data-toggle="modal" data-target="#exampleModalCenter">
            <img src="../../assets/images/ios-noti.png" alt="bell" />
            <span class="button__badge" v-if="notificationCount">{{ notificationCount }}</span>
          </div>
          <!-- Modal -->
          <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Notifications</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body over">
                  <p><strong>Orders & Refunds</strong></p>
                  <ul class="notifications-list flex-column align-items-start">
                    <li v-for="notification in notifications" :key="notification.NotificationID" class="p-3 w-100">
                      <!-- Display your notification content here -->
                      {{ notification.id }}
                      {{ notification.FirstName}}
                      {{ notification.LastName}}
                      {{ notification.Message }}
                      <p>Date: {{ notification.Timestamp | moment("MMM D, YYYY h:mm:ss A") }}</p>
                    </li>
                  </ul>
                  <p class="mt-2"><strong>Risky Customers</strong></p>
                  <ul class="Suspicious_notifications flex-column">
                      <li v-for="suspicious_noti in suspiciousNotifications" :key="suspicious_noti.SuspiciousActivityID" :class="{
                        'danger1': suspicious_noti.Risk_level === 'medium',
                        'danger2': suspicious_noti.Risk_level === 'high',
                        'danger3': suspicious_noti.Risk_level === 'extremely high'
                      }">
                        <!-- Display your notification content here -->
                        <span>Order id {{ suspicious_noti.OrderID }} with Customer id {{ suspicious_noti.CustomerID }} is at <strong>{{ suspicious_noti.Risk_level }}</strong> level of risk.</span>
                        <p>Date: {{ suspicious_noti.Timestamp | moment('MMM D, YYYY h:mm:ss A') }}</p>
                      </li>

                      <li v-for="suspicious_noti in suspiciousNotificationsRefund" :key="suspicious_noti.id" :class="{
                        'danger1': suspicious_noti.Risk_level === 'medium',
                        'danger2': suspicious_noti.Risk_level === 'high',
                        'danger3': suspicious_noti.Risk_level === 'extremely high'
                      }">
                        <!-- Display your notification content here -->
                        <span>Order id {{ suspicious_noti.OrderID }} with Customer id {{ suspicious_noti.CustomerID }} is at <strong>{{ suspicious_noti.Risk_level }}</strong> level of risk.</span>
                        <p>Date: {{ suspicious_noti.Timestamp | moment('MMM D, YYYY h:mm:ss A') }}</p>
                      </li>

                      <!-- Loop for VPN orders -->
                      <li v-for="vpnOrder in vpnOrders" :key="vpnOrder.OrderID" class="danger3">
                        <!-- Display VPN order notification content here -->
                        <span>Order id {{ vpnOrder.OrderID }} with Customer id {{ vpnOrder.CustomerID }} is done using VPN.</span>
                        <p>Date: {{ vpnOrder.Timestamp | moment('MMM D, YYYY h:mm:ss A') }}</p>
                      </li>
                    </ul>
                    <p class="mt-2"><strong>Vpn Orders</strong></p>
                    <ul class="Suspicious_notifications flex-column">
                    
                      <li v-for="vpnOrder in vpnOrders" :key="vpnOrder.OrderID" class="danger3">
                        <!-- Display VPN order notification content here -->
                        <span>Order id {{ vpnOrder.OrderID }} with Customer id {{ vpnOrder.CustomerID }} is done using VPN.</span>
                        <p>Date: {{ vpnOrder.Timestamp | moment('MMM D, YYYY h:mm:ss A') }}</p>
                      </li>
                    </ul>

                </div>
                
              </div>
            </div>
          </div>
        </li>
        <!-- <li class="nav-item">
          <div class="nav-link red-circle">
              <img src="../../assets/images/bell.png" alt="" />
              <span class="button__badge" >1</span>
            </div>
        </li> -->

        <li class="nav-item" @click="openUserMenu" v-click-outside="userMenuOutside">
          <a class="nav-link">
            <img src="../../assets/images/profile32.png" alt="" />
          </a>
        </li>
      </ul>
    </div>

    <div v-if="userMenu" class="menu-options">
      <ul>
        <a href="#" target="_blank" class="menu-link">Help</a>
        <a @click="logout" class="menu-link">Logout</a>
      </ul>
    </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import SocketioService from '../../services/socketio.service';

export default {
  name: "Navbar",
  data() {
    return {
      userMenu: false,
      isAdmin: false,
      isVerifier: false,
      enterpriseAdmin: false,
      notificationCount: 0,
      showNotifications: false,
      notifications: [],
      orders: [],
      refundOrders: [],
      riskNotifications: [],
      suspiciousNotifications: [],
      suspiciousNotificationsRefund:[],
      vpnOrderNotifications:[],
      vpnOrders: []
    };
  },
  created() {
    SocketioService.setupSocketConnection();

    SocketioService.socket.on('newOrder', (newOrder) => {
      console.log('New order received:', newOrder);
      this.notificationCount++;
      this.orders.push(newOrder);
      SocketioService.socket.emit('acknowledgeOrder', newOrder.id, (response) => {
        console.log('Acknowledgment received from server:', response);
      });
    });

    SocketioService.socket.on('newRefund', (newRefund) => {
      console.log('New refund received:', newRefund);
      this.notificationCount++;
      this.refundOrders.push(newRefund);
      SocketioService.socket.emit('acknowledgeRefund', newRefund, (response) => {
        console.log('Acknowledgment received from server for refund:', response);
      });
    });

    SocketioService.socket.on("mediumRiskNotification", (mediumRiskNotification)=>
    {
      console.log("New risk notification received", mediumRiskNotification)
      this.notificationCount++;
      this.riskNotifications.push(mediumRiskNotification);
      SocketioService.socket.emit('acknowledgeOrder',(response) => {
        console.log('Acknowledgment received from server:', response);
      });
      
    });
    SocketioService.socket.on('vpnOrderMismatch', (newVpnOrder) => {
    console.log('VPN Order mismatch received:', newVpnOrder);
    this.vpnOrderNotifications.push(newVpnOrder);
    this.notificationCount++;
    // this.vpnOrderMismatches.push(newVpnOrder); // Assuming you have an array to hold these mismatches
  });

    this.fetchNotifications();   
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    getNotificationData() {
      axios
        .get(process.env.VUE_APP_API_URL + "getNotifications")
        .then(response => {
          response.data.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          console.log(response);
          this.notifications=response.data.data
          this.notificationCount = 0;
        })
        .catch(() => {
          console.log("hello")
        });
    },
    getSuspiciousNotificationData() {
      axios
        .get(process.env.VUE_APP_API_URL + "getSuspiciousNotifications")
        .then(response => {
          response.data.Suspicious_Notifications.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          console.log(response);
          this.suspiciousNotifications=response.data.Suspicious_Notifications
          this.suspiciousNotificationsRefund=response.data.Suspicious_Notifications;
          this.vpnOrders=response.data.vpn_orders;
          

          this.notificationCount = 0;
        })
        .catch(() => {
          console.log("hello")
        });
    },
    openUserMenu() {
      this.userMenu = !this.userMenu;
    },
    logout: function() {
      this.sendLogoutRequest().then((response) => {
        if (response.data.statusCode == 200) {
          if (this.enterpriseAdmin) {
            this.$router.push({ name: "EnterpriseLogin" });
          } else {
            this.$router.push({ name: "Login" });
          }
        } else {
          console.log("error");
        }
      });
    },
    userMenuOutside() {
      this.userMenu = false;
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      this.getNotificationData();
      this.getSuspiciousNotificationData();
    },
    hideNotifications() {
      this.showNotifications = false;
    },
    fetchNotifications() {
      axios
        .get(process.env.VUE_APP_API_URL + "getNotifications")
        .then((response) => {
          response.data.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          console.log(response.data);
          this.notifications = response.data.data;
        })
        .catch(() => {
          console.log("hello")
        });
    },
  },
  mounted() {
    console.log(this.suspiciousNotifications)
    this.getNotificationData();
    this.getSuspiciousNotificationData();
    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    } else if (localStorage.getItem("permissions") == 4) {
      this.isVerifier = true;
    } else if (localStorage.getItem("permissions") == 5) {
      this.enterpriseAdmin = true;
    } else {
      this.isAdmin = false;
      this.isVerifier = false;
    }
  }
};
</script>

<style>
.red-circle {
  color: white;
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 2px 5px; /* Add some padding so it looks nice */
}
.button__badge {
  background-color: #fa3e3e;
  border-radius: 50%;
  color: white;
  border: 1px solid white;
  padding: 1px 4px;
  font-size: 10px;
  position: absolute;
  top: 1px;
  right: 7px;
}

.modal-content {
  max-height: 800px;
}
.modal-body {
  height: 700px;
}
.notifications-list {
  list-style: none;
  padding: 5px;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  height: 46%;
  overflow: auto;
}
.over{
  overflow: scroll;
}
.Suspicious_notifications {
  list-style: none;
  padding: 5px;
  border: 1px solid red;
  border-radius: 5px;
  height: 46%;
  overflow: auto;
}
.Suspicious_notifications li{
  list-style: none;
  padding: 5px;
  margin: 5px 0 5px 0;
  border-radius: 5px;
}
.notifications-list li {
  margin-bottom: 10px;
  background-color: #d3d3d387;
  border-radius: 5px;
}
.danger1{
  background-color: #ff000010;
}
.danger2{
  background-color: #ff000021;
}
.danger3{
  background-color: #ff000042;
}
</style>
